import React from "react";

const PageNotFound = () => {
  return (
    <div className='PageNotFound'>
      <h1>404</h1>
      <span>Page Not Found</span>
    </div>
  );
};

export default PageNotFound;
